import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import BlockchainApiManager from "../../app/services/blockchainApiManager";
import { Helmet } from "react-helmet";
import { useQuery } from "react-query";
import Skeleton from "react-loading-skeleton";

import ReactJson from "react-json-view";
import { WalletContext } from "../../app/contexts/wallet-context";
import { useContext, useEffect, useState } from "react";
import moment from "moment";
import PageTransition from "../../app/animations/page-transition";
import DisplayActionData from "../../app/components/blockchain/action/display-action-data";
import config from "../../app/config";
import { momentformat } from "../../app/utilities/date";
import ReactJsonTheme from "../../app/themes/react-json-theme";
import TransactionTraces from "./components/transaction-traces";
import { createBrowserHistory } from "history";

const Transaction = () => {
  const [timeLeft, setTimeLeft] = useState(null);
  const wallet = useContext(WalletContext);
  const { setMultisig } = wallet;
  let params = useParams();
  let navigate = useNavigate();
  const [transactionTraces, setTransactionTraces] = useState([]);

  const location = useLocation();
  const history = createBrowserHistory();
  let [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(
    searchParams.get("tab") || "actions"
  );

  const {
    isLoading,
    error,
    data: transaction,
  } = useQuery(
    [`transaction`, params.id],
    () => BlockchainApiManager.getTransaction(params.id),
    {
      onSuccess(transaction) {
        if (
          transaction &&
          transaction.trx &&
          (!transaction.trx.trx ||
            (transaction.trx.trx && transaction.trx.trx.actions.length === 0))
        ) {
          if (!transaction.trx.trx) {
            transaction.trx.trx = {};
          }
          if (!transaction.trx.trx.actions) {
            transaction.trx.trx.actions = [];
          }

          transaction.traces.forEach(function (trace) {
            const action = transaction.trx.trx.actions.find(
              (action) =>
                (action.hex_data === trace.act.hex_data &&
                  trace.act.hex_data) ||
                JSON.stringify(action) === JSON.stringify(trace.act)
            );
            if (!action) {
              transaction.trx.trx.actions.push(trace.act);
            }
          });
        }

        transaction.traces.forEach(function (trace) {
          //If action.data is hex then use decoded data from trace if exists
          if (
            transaction.trx &&
            transaction.trx.trx &&
            transaction.trx.trx.actions
          ) {
            const action = transaction.trx.trx.actions.find(
              (action) =>
                trace.act.hex_data && action.data === trace.act.hex_data
            );
            if (action && trace.act.data) action.data = trace.act.data;
          }
        });
      },
    }
  );

  const {
    isLoadingV2,
    errorV2,
    data: transactionV2,
  } = useQuery(
    [`transactionV2`, params.id],
    () => BlockchainApiManager.getV2Transaction(params.id),
    {}
  );

  useEffect(() => {
    if (transaction) {
      var transactionTraces = [];
      transaction.traces.forEach(function (trace) {
        const transactionTrace = transactionTraces.find(
          (transactionTrace) =>
            transactionTrace.act.hex_data === trace.act.hex_data &&
            trace.act.hex_data && 
            transactionTrace.closest_unnotified_ancestor_action_ordinal === trace.closest_unnotified_ancestor_action_ordinal
        );
        if (!transactionTrace) {
          transactionTraces.push(trace);
        }
      });
      setTransactionTraces(transactionTraces);
    }

    if (transaction && transactionV2) {
      if (!transaction.trx.receipt) {
        transactionV2.actions.forEach(function (action) {
          if (action.cpu_usage_us) {
            transaction.trx.receipt.cpu_usage_us = action.cpu_usage_us;
          }
          if (action.net_usage_words) {
            transaction.trx.receipt.net_usage_words = action.net_usage_words;
          }
        });
      }
    }
  }, [transaction, transactionV2]);

  useEffect(() => {
    // exit early when we reach 0
    if (!timeLeft) return;

    if (timeLeft === 0) {
      setTimeLeft(null);
    }

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);

  useEffect(() => {
    if (!transaction) return;

    setTimeLeft(
      180 - moment().diff(moment(transaction.block_time + "Z"), "seconds")
    );
  }, [transaction]);

  const copyAsMsig = (e) => {
    e.preventDefault();

    var walletTransaction = {};
    walletTransaction.type = "msig";
    walletTransaction.actions = [];

    if (
      transaction &&
      transaction.trx &&
      transaction.trx.trx &&
      transaction.trx.trx.actions
    ) {
      transaction.trx.trx.actions.forEach(function (action) {
        walletTransaction.actions.push(action);
      });
    }

    //wallet.multisig.mode = true;
    //wallet.multisig.walletTransaction = Object.assign({}, walletTransaction);

    setMultisig((multisig) => ({
      ...multisig,
      mode: true,
      walletTransaction: Object.assign({}, walletTransaction),
    }));

    navigate("/wallet/msig");
  };

  if (!isLoading && (error || !transaction || !transaction.id)) {
    return (
      <>
        <div className="block_detail_sec">
          <div className="cont_sec acc_sec">
            <h2>Transaction Not Found</h2>
            <p>
              Cannot find Transaction with the ID {params.id}. Please check the
              transaction ID or try again after a few minutes.{" "}
            </p>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{params.id} | Transaction - WAX Explorer</title>
      </Helmet>
      <div className="block_detail_sec">
        <div className="cont_sec acc_sec">
          <h2>Transaction</h2>
          <div className="util_box">
            <div className="util_block">
              <div className="block_id">
                <h3>
                  Transaction #
                  {(transaction && transaction.id) || (
                    <Skeleton style={{ width: "80%" }} />
                  )}
                  <span>
                    {(transaction &&
                      momentformat(
                        transaction.block_time,
                        "MMM DD YYYY, HH:mm:ss.SSS"
                      )) || <Skeleton />}
                  </span>
                </h3>
              </div>
              <div className="performance_box">
                <ul className="performance">
                  <li>
                    {transaction ? (
                      <Link to={`/block/${transaction.block_num}`}>
                        {parseInt(transaction.block_num).toLocaleString()}
                      </Link>
                    ) : (
                      <Skeleton />
                    )}
                    <span>Block Number</span>
                  </li>

                  <li>
                    {transaction ? (
                      <>{transaction.trx.receipt.cpu_usage_us} µs</>
                    ) : (
                      <Skeleton style={{ width: "200px" }} />
                    )}
                    <span>CPU Usage</span>
                  </li>
                  <li>
                    {transaction ? (
                      <>{transaction.trx.receipt.net_usage_words * 8} Bytes</>
                    ) : (
                      <Skeleton style={{ width: "200px" }} />
                    )}
                    <span>NET Usage</span>
                  </li>
                  <li>
                    {transaction &&
                    transaction.trx &&
                    transaction.trx.trx &&
                    transaction.trx.trx.actions ? (
                      <>{transaction.trx.trx.actions.length}</>
                    ) : (
                      <Skeleton style={{ width: "200px" }} />
                    )}
                    <span># of Actions</span>
                  </li>
                  <li>
                    {transaction ? (
                      <>{transactionTraces.length}</>
                    ) : (
                      <Skeleton style={{ width: "200px" }} />
                    )}
                    <span># of Traces</span>
                  </li>
                  <li>
                    {transaction ? (
                      <>
                        {/* {transaction.trx.receipt.status === "executed" ? (
                          <span className="type">Executed</span>
                        ) : null} */}
                        {moment().diff(
                          moment(transaction.block_time + "Z"),
                          "seconds"
                        ) <= 180 ? (
                          <>Pending Irreversibility ({timeLeft}s)</>
                        ) : (
                          <>Irreversible</>
                        )}
                      </>
                    ) : (
                      <Skeleton style={{ width: "200px" }} />
                    )}
                    <span>Status</span>
                  </li>
                </ul>
                {/*<ul className="performance">
                 
                 <li>
                    0e3f7c1468ab120fd56ad687fa...<span>Block ID</span>
                  </li>
                  <li>
                    1,504.91 M<span>Total Block CPU</span>
                  </li>
                  <li>
                    238500 K<span>Total Block NET</span>
                  </li>
                  <li>
                    13<span>Number of Actions</span>
                  </li>
                  <li>
                    9<span>Number of Transactions</span>
                  </li> 
                </ul>*/}
              </div>
              <a href="#" className="more_btn" onClick={copyAsMsig}>
                Copy As MSIG
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="cont_sec tab_sec block_detail">
        <h2>Transaction Summary</h2>
        <ul className="tabbbing">
          <li className={`${activeTab === "actions" ? "active" : ""}`}>
            <a
              href="#actions"
              onClick={() => {                
                setSearchParams({ tab: "actions" });
                setActiveTab("actions");
              }}
            >
              Actions
            </a>
          </li>
          <li className={`${activeTab === "traces" ? "active" : ""}`}>
            <a
              href="#traces"
              onClick={() => {               
                setSearchParams({ tab: "traces" });
                setActiveTab("traces");
              }}
            >
              Traces
            </a>
          </li>
          <li className={`${activeTab === "ram" ? "active" : ""}`}>
            <a
              href="#ram"
              onClick={() => {                
                setSearchParams({ tab: "ram" });
                setActiveTab("ram");
              }}
            >
              RAM Deltas
            </a>
          </li>
          <li className={`${activeTab === "raw" ? "active" : ""}`}>
            <a
              href="#raw"
              onClick={() => {                
                setSearchParams({ tab: "raw" });
                setActiveTab("raw");
              }}
            >
              Raw
            </a>
          </li>
        </ul>
        <div
          className={`tab actions ${activeTab === "actions" ? "active" : ""}`}
          id="actions"
        >
          <table>
            <thead>
              <tr>
                <th style={{ width: "10%" }}>Contract</th>
                <th style={{ width: "10%" }}>Name</th>
                <th style={{ width: "15%" }}>Authorization</th>
                <th style={{ width: "65%" }}>Data</th>
              </tr>
            </thead>
            <tbody>
              {!transaction &&
                Array(5)
                  .fill(0)
                  .map((item, index) => (
                    <tr key={index}>
                      <td style={{ width: "10%" }}>
                        <Skeleton />
                      </td>
                      <td style={{ width: "10%" }}>
                        <Skeleton />
                      </td>
                      <td style={{ width: "15%" }}>
                        <Skeleton />
                      </td>
                      <td style={{ width: "65%" }}>
                        <Skeleton />
                      </td>
                    </tr>
                  ))}
              {transaction &&
                transaction.trx &&
                transaction.trx.trx &&
                transaction.trx.trx.actions.map((action, index) => (
                  <tr key={index}>
                    <td style={{ width: "10%" }}>
                      <Link to={`/account/${action.account}`}>
                        {action.account}
                      </Link>
                    </td>
                    <td style={{ width: "10%" }}>{action.name}</td>
                    <td style={{ width: "15%" }}>
                      {action.authorization.map((authorization) => (
                        <>
                          <div style={{ paddingBottom: "5px" }}>
                            <Link
                              to={`/account/${authorization.actor}`}
                              className="btn btn-primary  btn-sm"
                            >
                              <span className="type">
                                {" "}
                                {authorization.actor}
                              </span>
                            </Link>
                            &nbsp;
                            <span className="type">
                              {authorization.permission}
                            </span>
                          </div>
                        </>
                      ))}
                    </td>
                    <td style={{ width: "65%" /*wordBreak:'break-all'*/ }}>
                      <DisplayActionData action={action} />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div
          className={`tab traces ${activeTab === "traces" ? "active" : ""}`}
          id="traces"
        >
          <TransactionTraces
            transaction={transaction}
            transactionTraces={transactionTraces}
          />
          {/* <table>
            <thead>
              <tr>
                <th style={{ width: "10%" }}>Contract</th>
                <th style={{ width: "10%" }}>Name</th>
                <th style={{ width: "15%" }}>Authorization</th>
                <th style={{ width: "65%" }}>Data</th>
              </tr>
            </thead>
            <tbody>
              {!transaction &&
                Array(5)
                  .fill(0)
                  .map((item, index) => (
                    <tr key={index}>
                      <td style={{ width: "10%" }}>
                        <Skeleton />
                      </td>
                      <td style={{ width: "10%" }}>
                        <Skeleton />
                      </td>
                      <td style={{ width: "15%" }}>
                        <Skeleton />
                      </td>
                      <td style={{ width: "65%" }}>
                        <Skeleton />
                      </td>
                    </tr>
                  ))}
              {transaction &&
                transactionTraces.map((trace, index) => (
                  <tr key={index}>
                    <td style={{ width: "10%" }}>
                      <Link to={`/account/${trace.act.account}`}>
                        {trace.act.account}
                      </Link>
                    </td>
                    <td style={{ width: "10%" }}>{trace.act.name}</td>
                    <td style={{ width: "15%" }}>
                      {trace.act.authorization.map((authorization) => (
                        <>
                          <div style={{ paddingBottom: "5px" }}>
                            <Link
                              to={`/account/${authorization.actor}`}
                              className="btn btn-primary  btn-sm"
                            >
                              <span className="type">
                                {" "}
                                {authorization.actor}
                              </span>
                            </Link>
                            &nbsp;
                            <span className="type">
                              {authorization.permission}
                            </span>
                          </div>
                        </>
                      ))}
                    </td>
                    <td style={{ width: "65%"  }}>
                      <DisplayActionData action={trace.act} />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table> */}
        </div>
        <div
          className={`tab ram ${activeTab === "ram" ? "active" : ""}`}
          id="ram"
        >
          <table>
            <thead>
              <tr>
                <th>Account</th>
                <th>Change</th>
              </tr>
            </thead>
            <tbody>
              {!transaction &&
                Array(5)
                  .fill(0)
                  .map((item, index) => (
                    <tr key={index}>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                  ))}
              {transaction &&
                transaction.traces &&
                transaction.traces
                  .filter((trace) => trace.account_ram_deltas.length > 0)
                  .map((trace) => (
                    <>
                      {trace.account_ram_deltas.map((account_ram_delta) => (
                        <tr>
                          <td>{account_ram_delta.account}</td>
                          <td>
                            {account_ram_delta.delta > 0
                              ? "Consumed"
                              : "Released"}{" "}
                            {Math.abs(account_ram_delta.delta)} Bytes
                          </td>
                        </tr>
                      ))}
                    </>
                  ))}

              {transaction &&
              transaction.traces &&
              transaction.traces.filter(
                (trace) => trace.account_ram_deltas.length > 0
              ).length === 0 ? (
                <tr>
                  <td colSpan={2}>No RAM Deltas for this transaction</td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
        <div
          className={`tab raw ${activeTab === "raw" ? "active" : ""}`}
          id="raw"
        >
          <ReactJson
            src={transaction}
            //theme="monokai"
            theme={ReactJsonTheme}
            style={{ padding: "15px", overflow: "auto" }}
          />
        </div>
      </div>
    </>
  );
};

export default Transaction;
